/**
 * @generated SignedSource<<115946c66b973f7aa71fe4859d7482f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SegmentSource = "SEGMENT_SOURCE_DYNAMIC" | "SEGMENT_SOURCE_INTERNAL_API" | "SEGMENT_SOURCE_MANUAL_UPLOAD" | "SEGMENT_SOURCE_THIRD_PARTY" | "SEGMENT_SOURCE_UNKNOWN" | "%future added value";
export type SegmentSubscribersPageEntrypoint_SegmentationUI_Query$variables = {
  companyId: string;
  segmentId: string;
};
export type SegmentSubscribersPageEntrypoint_SegmentationUI_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SegmentSubscribers_SegmentationUI_Query">;
};
export type SegmentSubscribersPageEntrypoint_SegmentationUI_Query$rawResponse = {
  readonly company: {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly segment: {
    readonly __typename: "Segment";
    readonly id: string;
    readonly name: string;
    readonly source: SegmentSource;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SegmentSubscribersPageEntrypoint_SegmentationUI_Query = {
  rawResponse: SegmentSubscribersPageEntrypoint_SegmentationUI_Query$rawResponse;
  response: SegmentSubscribersPageEntrypoint_SegmentationUI_Query$data;
  variables: SegmentSubscribersPageEntrypoint_SegmentationUI_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "segmentId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SegmentSubscribersPageEntrypoint_SegmentationUI_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SegmentSubscribers_SegmentationUI_Query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SegmentSubscribersPageEntrypoint_SegmentationUI_Query",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "segment",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "segmentId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "source",
                "storageKey": null
              }
            ],
            "type": "Segment",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c76b09caadee67ff47979c273516415",
    "id": null,
    "metadata": {},
    "name": "SegmentSubscribersPageEntrypoint_SegmentationUI_Query",
    "operationKind": "query",
    "text": "query SegmentSubscribersPageEntrypoint_SegmentationUI_Query(\n  $companyId: ID!\n  $segmentId: ID!\n) {\n  ...SegmentSubscribers_SegmentationUI_Query\n}\n\nfragment SegmentSubscribers_SegmentationUI_Query on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      id\n    }\n    id\n  }\n  segment: node(id: $segmentId) {\n    __typename\n    ... on Segment {\n      id\n      name\n      source\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfd5570797b6fa32e33066a5fb5ad3a9";

export default node;
